body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.new-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #201640;
  color: #333;
  font-family: Arial, sans-serif;
}

.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 5px;
  background-color: aliceblue;
  border: 1px solid #333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
